import React, { Suspense, useEffect, useState } from 'react';

import SlideOver from '@components/UI/SlideOver';

import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { getFullname } from '@utility/Utils';

import Spinner from '@components/UI/Spinner';

import congesService from '@data/congesService';

const HomeChart = React.lazy(() => import('@components/Dashboard/Chart'));

const TooltipTitle = () => {
  const { t } = useTranslation();
  return (
    <>
      <span>
        {t('soldeLeaveAvailable')} <br />
      </span>
      <span>
        {t('tutoFutureLeaves')} <br />
      </span>
      <span>{t('tutoTaken')}</span>
    </>
  );
};

const CounterSlideOver = ({ isOpen, setIsOpen, values }) => {
  const onClose = () => setIsOpen(false);
  const { t } = useTranslation();

  const [userLeaveCount, setUserLeaveCount] = useState([]);

  const fetchCounters = async () => {
    const response = await congesService.post(
      '/v1/user-leaves-count/search?include=futureLeaves,leave_type',
      {
        filters: [
          {
            field: 'user_id',
            operator: '=',
            value: values.id,
          },
        ],
      }
    );
    setUserLeaveCount(
      response.data.data.map((element) => {
        return {
          ...element,
          balance:
            Math.round(
              (element.acquired - element.futureLeaves - element.taken) * 100
            ) / 100,
        };
      })
    );
  };

  useEffect(() => {
    fetchCounters();
  }, [isOpen]);

  const paidLeave = userLeaveCount.find((leave) => leave.leave_type.name === 'Congés payés');
  const mainColor = paidLeave?.leave_type?.color.replace(/0xFF|0xff/, '#');

  return (
    <>
      <SlideOver
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={onClose}
        title={`${getFullname(values)}`}
      >
        <div className='flex items-center justify-end my-8 mx-6 '>
          <ul className='flex align-center gap-x-8'>
            <li className='flex items-center gap-x-2'>
              <span
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: mainColor }}
              />
              <span>{t('solde').toUpperCase()}</span>
            </li>
            <li className='flex items-center gap-x-2'>
              <span
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: mainColor + 80 }}
              />
              <span> {t('estimatedLeaves').toUpperCase()}</span>
            </li>
            <li className='flex items-center gap-x-2'>
              <span className='w-3 h-3 bg-[#2B2E42] rounded-full' />
              <span>{t('taken').toUpperCase()}</span>
            </li>
            <li>
              <Tooltip title={<TooltipTitle />} arrow placement='bottom'>
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </li>
          </ul>
        </div>
        <div className='flex flex-col justify-center items-center gap-y-6'>
          {userLeaveCount.map((value, index) => {
            return (
              <div
                key={index}
                className='flex flex-col justify-center items-center min-h-[140px] shadow-card rounded-md w-2/3'
              >
                <h3 className='text-center my-3 font-montserrat text-primary1 font-semibold '>
                  {`${t(value?.leave_type?.name)} ${value?.is_last_year ? '(N-1)' : ''
                    }`}
                </h3>
                <Suspense fallback={<Spinner />}>
                  <HomeChart leave={value} />
                </Suspense>
              </div>
            );
          })}
        </div>
      </SlideOver>
    </>
  );
};

export default CounterSlideOver;
