import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Checkbox,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    List,
    Popover,
    Collapse,
    Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { useDebounce } from '@uidotdev/usehooks';
import Spinner from '@components/UI/Spinner';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { VariableSizeList as VirtualizedList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const USERS_PER_PAGE = 20;

export const SelectRequestCollab = ({
    dataList,
    dataSearch,
    search,
    setSearch,
    searchContent,
    selected,
    setSelected,
    maxPage,
    page,
    setPage,
    setAllSelected,
    allSelected,
    isLoading,
    setIsLoading,
    teamFormat,
    openSelect,
    setOpenSelect,
    setNotSelected,
    numberSelected,
    showList,
    userNotFound
}) => {
    const [expanded, setExpanded] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [result, setResult] = useState([]);
    const [itemRendered, setItemRendered] = useState(showList ? dataList.length : dataSearch.length);
    const [userPages, setUserPages] = useState({});
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const listRef = useRef(null);

    const itemHeight = 58;

    const debouncedSearch = useDebounce(searchValue, 600);

    useEffect(() => {
        setSearch(searchValue);
    }, [debouncedSearch, setSearch]);

    const handleSearch = (value) => {
        setSearchValue(value);
        value.length === 0 ? searchContent(false) : searchContent(true);
    };

    const handleMenuScrollToBottom = () => {
        if (!isLoading && page < maxPage && searchValue.length === 0) {
            setIsLoading(true);
            setPage(prev => prev + 1);
        }
    };

    const handleToggleExpand = (deptId) => {
        setExpanded(prev => {
            const isCurrentlyExpanded = prev.includes(deptId);
            const newExpanded = isCurrentlyExpanded
                ? prev.filter(id => id !== deptId)
                : [...prev, deptId];

            if (isCurrentlyExpanded) {
                setUserPages(prevPages => {
                    const newPages = { ...prevPages };
                    delete newPages[deptId];
                    return newPages;
                });
            }

            return newExpanded;
        });

        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
        inputRef.current?.focus();
    };

    const handleToggleUser = (user) => {
        setSelected((prev) => {
            if (prev.some((selectedUser) => selectedUser.id === user.id)) {
                allSelected && setNotSelected((prev) => [...prev, user]);
                return prev.filter((selectedUser) => selectedUser.id !== user.id);
            } else {
                allSelected && setNotSelected((prev) => [...prev, user]);
                return [...prev, user];
            }
        });

        inputRef.current?.focus();
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelected([]);
            setAllSelected(false);
        } else {
            const allUsers = teamFormat
                ? [...new Map(result.flatMap(team => team.users || []).map(user => [user.id, user])).values()]
                : result;

            setNotSelected([]);
            setSelected(allUsers);
            setAllSelected(true);
        }
        inputRef.current?.focus();
    };

    const indeterminate = allSelected && selected.length < result.length;

    const handleOpen = (event) => {
        if (!anchorEl) {
            const target = event?.currentTarget || containerRef.current;
            setAnchorEl(target);
            inputRef.current?.focus();
            showList && updateResultList();
        }
    };

    const updateResultList = () => {
        if (teamFormat) {
            const selectedIds = new Set(selected.map(user => user.id));

            const updatedTeams = dataList.map(team => {
                const selectedUsers = team.users.filter(user => selectedIds.has(user.id));
                const nonSelectedUsers = team.users.filter(user => !selectedIds.has(user.id));

                return {
                    ...team,
                    users: [...selectedUsers, ...nonSelectedUsers],
                };
            });

            setResult(updatedTeams);
        } else {
            const selectedIds = new Set(selected.map(user => user.id));
            const selectedUsers = [...selected];
            const nonSelectedUsers = result.filter(user => !selectedIds.has(user.id));

            setResult([...selectedUsers, ...nonSelectedUsers]);
        }
    };

    useEffect(() => {
        showList && updateResultList();
    }, [search])

    useEffect(() => {
        if (openSelect) {
            handleOpen();
            setOpenSelect(false);
        }
    }, [openSelect, handleOpen]);

    useEffect(() => {
        const dataWithoutSelected = allSelected ? dataList : dataList.filter(user => !selected.some(selectedUser => selectedUser.id === user.id));
        setResult((prev) => [...prev, ...dataWithoutSelected]);
    }, [dataList]);

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded([]);
        setUserPages({});
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    };

    const handleClickTeams = (team, e) => {
        e.stopPropagation();
        const listUsers = team.users || [];
        if (e.target.checked) {
            setSelected(prev => {
                const newUsers = listUsers.filter(user =>
                    !prev.some(selectedUser => selectedUser.id === user.id)
                );
                return [...prev, ...newUsers];
            });
        } else {
            setSelected(prev => prev.filter(user => !listUsers.some(teamUser => teamUser.id === user.id)));
        }
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            inputRef.current?.focus();
        }
    }, [open]);

    useEffect(() => {
        showList ? setItemRendered(dataList.length) : setItemRendered(dataSearch.length);
        setExpanded([]);
        setUserPages({});
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [dataSearch, showList]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [userPages]);

    const item = showList ? result : dataSearch;

    const itemSize = (index) => {

        const team = item[index];
        expanded.length === 0 && showList && setItemRendered(result.length);
        if (teamFormat && showList && expanded.includes(team.id)) {
            const userCount = Math.min((userPages[team.id] || 1) * USERS_PER_PAGE, team.users.length);
            setItemRendered(result.length + team.users.length);
            const hasMoreUsers = team.users.length > userCount;
            return itemHeight + userCount * itemHeight + (hasMoreUsers ? itemHeight : 0);
        }
        return itemHeight;
    };


    const loadMoreUsers = useCallback((teamId) => {
        setUserPages(prev => ({
            ...prev,
            [teamId]: (prev[teamId] || 1) + 1
        }));
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, []);

    const renderRow = useCallback(({ index, style }) => {
        const team = item[index];
        if (teamFormat && showList) {
            const isExpanded = expanded.includes(team.id);
            const currentPage = userPages[team.id] || 1;
            const displayedUsers = team.users.slice(0, currentPage * USERS_PER_PAGE);
            const hasMoreUsers = team.users.length > displayedUsers.length;

            return (
                <div style={style}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleToggleExpand(team.id)}>
                            <Checkbox
                                edge="start"
                                checked={team.users?.every(user =>
                                    selected.some(selectedUser => selectedUser.id === user.id)
                                )}
                                indeterminate={
                                    team.users?.some(user =>
                                        selected.some(selectedUser => selectedUser.id === user.id)
                                    ) &&
                                    !team.users?.every(user =>
                                        selected.some(selectedUser => selectedUser.id === user.id)
                                    )
                                }
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#e20917',
                                    },
                                    '&.MuiCheckbox-indeterminate': {
                                        color: "#e20917",
                                    },
                                }}
                                onClick={(e) => handleClickTeams(team, e)}
                            />
                            <ListItemText
                                primary={<div style={{ fontWeight: 'bold' }}>{`${team.label} (${team.users?.length})`}</div>}
                            />
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={isExpanded} timeout="auto">
                        <List component="div" disablePadding>
                            {displayedUsers.map((user) => (
                                <ListItem key={user.id} disablePadding sx={{ pl: 4 }}>
                                    <ListItemButton onClick={() => handleToggleUser(user)}>
                                        <Checkbox
                                            edge="start"
                                            checked={selected.some(selectedUser => selectedUser.id === user.id)}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#e20917',
                                                },
                                                '&.MuiCheckbox-indeterminate': {
                                                    color: "#e20917",
                                                },
                                            }}
                                        />
                                        <ListItemText primary={user.lastname + ' ' + user.firstname} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        {hasMoreUsers && (
                            <ListItem disablePadding sx={{ pl: 4 }}>
                                <Button onClick={() => loadMoreUsers(team.id)}>
                                    Charger plus...
                                </Button>
                            </ListItem>
                        )}
                    </Collapse>
                </div>
            );
        } else {
            const user = item[index];
            return (
                <ListItem key={user.id} disablePadding style={style}>
                    <ListItemButton onClick={() => handleToggleUser(user)}>
                        <Checkbox
                            edge="start"
                            checked={selected.some(selectedUser => selectedUser.id === user.id)}
                            sx={{
                                '&.Mui-checked': {
                                    color: '#e20917',
                                },
                                '&.MuiCheckbox-indeterminate': {
                                    color: "#e20917",
                                },
                            }}
                        />
                        <ListItemText primary={user.lastname + ' ' + user.firstname} />
                    </ListItemButton>
                </ListItem>
            );
        }
    }, [item, expanded, userPages, teamFormat, showList, selected, handleToggleUser, handleClickTeams, loadMoreUsers]);

    return (
        <Box sx={{ width: '100%', marginBottom: '14px' }} ref={containerRef}>
            <Box sx={{ position: 'relative', width: '100%', height: '48px' }}>
                <label className='text-sm font-medium text-gray-700'>Collaborateur*</label>
                <Box
                    onClick={handleOpen}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        height: '48px',
                    }}
                >
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchValue}
                        placeholder='Collaborateurs'
                        onChange={(e) => handleSearch(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        className='text-sm'
                        style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            width: '100%',
                            color: '#333',
                            cursor: 'pointer',
                        }}
                    />
                    <div className="flex items-center justify-center min-w-8 min-h-8 px-2 py-1 text-white bg-[#e20917] rounded-full text-sm mr-4">
                        {numberSelected}
                    </div>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        className={`ml-auto h-4 w-4 transition-transform duration-300 mr-4 ${open ? '-rotate-180' : ''}`}
                    />
                </Box>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                sx={{ marginTop: '10px', width: '100%' }}
                onClick={(e) => e.stopPropagation()}
            >
                <Paper
                    sx={{
                        width: '520px',
                        maxHeight: '500px',
                        height: 'auto',
                        overflow: 'hidden'
                    }}
                >
                    {userNotFound ? (
                        <ListItemButton disabled>
                            <ListItemText
                                primary="Aucun collaborateur trouvé"
                                primaryTypographyProps={{
                                    style: {
                                        fontStyle: 'italic',
                                        textAlign: 'center',
                                        width: '100%'
                                    }
                                }}
                            />
                        </ListItemButton>
                    ) : (
                        <>
                            {showList && (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={handleSelectAll} className='mt-1'>
                                            <Checkbox
                                                edge="start"
                                                checked={allSelected}
                                                indeterminate={indeterminate}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#e20917',
                                                    },
                                                    '&.MuiCheckbox-indeterminate': {
                                                        color: "#e20917",
                                                    },
                                                }}
                                            />
                                            <ListItemText primary="Sélectionner tous les collaborateurs" />
                                        </ListItemButton>
                                    </ListItem>
                                    <hr className='mx-4 border-t-2' />
                                </>
                            )}
                            <AutoSizer disableHeight>
                                {({ width }) => {
                                    const listHeight = Math.min(itemRendered * itemHeight, 440);

                                    return (
                                        <VirtualizedList
                                            height={listHeight}
                                            width={width - 20}
                                            itemCount={item.length}
                                            itemSize={itemSize}
                                            overscanCount={5}
                                            ref={listRef}
                                            className='vertical-scrollbar'
                                            onItemsRendered={({ visibleStopIndex }) => {
                                                if (visibleStopIndex === item.length - 1) {
                                                    handleMenuScrollToBottom();
                                                }
                                            }}
                                            style={{ overflowX: 'hidden', overflowY: 'auto' }}
                                        >
                                            {renderRow}
                                        </VirtualizedList>
                                    )
                                }}
                            </AutoSizer>
                            {isLoading && (
                                <ListItem style={{ display: 'flex', justifyContent: 'center', height: '48px' }}>
                                    <Spinner height={40} width={40} />
                                </ListItem>
                            )}
                        </>
                    )}
                </Paper>
            </Popover>
        </Box>
    );
};

export default SelectRequestCollab;

