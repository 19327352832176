import React from "react"
import { X } from 'lucide-react'

export const SelectedCollaborator = ({ selected, setSelected, openSelect, numberSelected, removeCollabTag }) => {
  const hasMore = selected.length > 10

  return (
    <div className="mt-8">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Collaborateurs ({numberSelected}) :</label>
          <button
            className="p-1 rounded-full hover:bg-gray-100"
            onClick={(e) => {
              e.preventDefault()
              setSelected([])
            }}
          >
            <X className="w-4 h-4 text-gray-500" />
          </button>
        </div>
        <div className="flex flex-wrap gap-2">
          {selected.slice(0, 10).map(tag => (
            <span
              key={tag.id}
              className="inline-flex items-center px-3 py-1 rounded-md bg-gray-100 text-sm"
            >
              {tag.lastname} {tag.firstname}
              <button
                onClick={() => removeCollabTag(tag)}
                className="ml-2 hover:bg-gray-200 rounded-full p-0.5"
              >
                <X className="w-3 h-3 text-gray-500" />
              </button>
            </span>
          ))}
          {hasMore && (
            <button
              onClick={(e) => openSelect(e)}
              className="text-[#1473E6] text-sm cursor-pointer hover:text-blue-800"
            >
              Voir plus ({numberSelected - 10})
            </button>
          )}
        </div>
        {selected.length > 1 && (
          <p className="text-xs text-red-500">
            En cas de problèmes lors de la création du congé, un rapport d'erreurs sera téléchargé
          </p>
        )}
      </div>
    </div >
  )
}

